<template>
  <div class="courseForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="年级分类"
        :rules="[
          { required: true, message: '请选择年级分类', trigger: 'blur' },
        ]"
        prop="gradeType"
      >
        <v-select disabled :options="gradeList" v-model="form.gradeType" />
      </el-form-item>
      <el-form-item
        label="科目分类"
        :rules="[
          { required: true, message: '请选择科目分类', trigger: 'blur' },
        ]"
        prop="courseType"
      >
        <v-select disabled :options="classes" v-model="form.courseType" />
      </el-form-item>
      <el-form-item
        label="章节名称"
        :rules="[
          { required: true, message: '请输入章节名称', trigger: 'blur' },
        ]"
        prop="chapterId"
      >
        <v-select
          disabled
          filterable
          :options="chapterList"
          v-model="form.chapterId"
        />
      </el-form-item>

      <el-form-item
        label="图片"
        :rules="[
          {
            required: true,
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="imageUrl"
      >
        <v-upload :limit="1" :imgUrls.sync="form.imageUrl" />
      </el-form-item>

      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入排序"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="课程名称"
        :rules="[
          { required: true, message: '请输入课程名称', trigger: 'blur' },
        ]"
        prop="courseTitle"
      >
        <v-input
          placeholder="请输入课程名称"
          v-model="form.courseTitle"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="老师"
        :rules="[{ required: true, message: '请输入老师', trigger: 'blur' }]"
        prop="teacher"
      >
        <v-input
          placeholder="请输入老师"
          v-model="form.teacher"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="学校"
        :rules="[{ required: true, message: '请输入学校', trigger: 'blur' }]"
        prop="school"
      >
        <v-input
          placeholder="请输入学校"
          v-model="form.school"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="课程简介"
        :rules="[
          { required: true, message: '请输入课程简介', trigger: 'blur' },
        ]"
        prop="courseDesc"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.courseDesc" />
        </div>
      </el-form-item>
      <el-form-item
        label="教学目标"
        :rules="[
          { required: true, message: '请输入教学目标', trigger: 'blur' },
        ]"
        prop="courseObjective"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.courseObjective" />
        </div>
      </el-form-item>
      <el-form-item
        label="上传视频"
        :rules="[
          { required: false, message: '请选择上传方式', trigger: 'blur' },
        ]"
        prop="uploadWay"
      >
        <v-radio
          :radioObj="radioObj"
          v-model="form.uploadWay"
          @change="change"
        ></v-radio>
      </el-form-item>
      <el-form-item
        v-if="form.uploadWay == 1"
        label="视频链接"
        :rules="[
          { required: false, message: '请选择视频链接', trigger: 'blur' },
        ]"
        prop="videoUrl"
      >
        <v-input
          placeholder="请输入视频链接"
          v-model="form.videoUrl"
          :width="450"
        ></v-input>
      </el-form-item>
      <el-form-item
        v-if="form.uploadWay == 2"
        label="直接上传"
        :rules="[{ required: false, message: '请上传视频', trigger: 'blur' }]"
        prop="videoUrl"
      >
        <v-videoUpload :limit="1" :videoUrls.sync="form.videoUrl" />
      </el-form-item>
      <el-form-item
        label="上传课件"
        :rules="[
          { required: false, message: '请选择上传方式', trigger: 'blur' },
        ]"
        prop="uploadClassWay"
      >
        <v-radio
          :radioObj="radioObj"
          v-model="form.uploadClassWay"
          @change="change"
        ></v-radio>
      </el-form-item>
      <el-form-item
        v-if="form.uploadClassWay == 1"
        label="课件链接"
        :rules="[
          { required: false, message: '请选择课件链接', trigger: 'blur' },
        ]"
        prop="attachmentUrl"
      >
        <v-input
          placeholder="请输入课件链接"
          v-model="form.attachmentUrl"
          :width="450"
        ></v-input>
      </el-form-item>
      <el-form-item
        v-if="form.uploadClassWay == 2"
        label="直接上传"
        :rules="[{ required: false, message: '请上传课件', trigger: 'blur' }]"
        prop="attachmentUrl"
      >
        <v-fileUpload :limit="1" :imgUrls.sync="form.attachmentUrl" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { getCourseDetailUrl, getChapterListUrl, addCourseUrl } from "./api";
import {
  classesMap,
  classesOps,
  gradeListMap,
  classes,
  gradeList,
} from "./map";
export default {
  name: "courseForm",
  data() {
    let _this = this;
    return {
      radioObj: [
        { name: "1", value: "链接" },
        { name: "2", value: "直接上传" },
      ],
      chapterList: [],
      gradeList,
      classes,
      submitConfig: {
        queryUrl: getCourseDetailUrl,
        submitUrl: addCourseUrl,
      },
      teacherSignList: [],
      form: {
        id: "",
        gradeType: Number(_this.$route.query.gradeType),
        chapterTitle: "",
        chapterId: Number(_this.$route.query.chapterId),
        courseType: Number(_this.$route.query.courseType),
        imageUrl: "",
        sortIndex: 0,
        courseDesc: "",
        school: "",
        teacher: "",
        courseTitle: "",
        courseObjective: "",
        uploadWay: "1",
        videoUrl: "",
        uploadClassWay: "1",
        attachmentUrl: "",
      },
    };
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ courseId: id });
    } else {
    }
    this.getChapterList();
    this.$setBreadList(
      id
        ? [{ title: "课程管理" }, { title: "编辑" }]
        : [{ title: "课程管理" }, { title: "新增" }]
    );
  },
  methods: {
    change() {},
    getChapterList() {
      let params = {};
      this.chapterList = [];
      this.$axios.get(`${getChapterListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            res.data.records.forEach((item) => {
              item.label = item.chapterTitle;
              item.value = item.id;
              this.chapterList.push(item);
            });
          }
        }
      });
    },
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.form.id = data.id;
      this.form.uploadWay = "2";
      this.form.uploadClassWay = "2";
    },
  },
};
</script>
<style lang="less" scoped>
.courseForm {
  box-sizing: border-box;
  height: 100%;
}
</style>
<style lang="less">
.courseForm {
}
</style>
