var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"年级分类","rules":[
          { required: true, message: '请选择年级分类', trigger: 'blur' },
        ],"prop":"gradeType"}},[_c('v-select',{attrs:{"disabled":"","options":_vm.gradeList},model:{value:(_vm.form.gradeType),callback:function ($$v) {_vm.$set(_vm.form, "gradeType", $$v)},expression:"form.gradeType"}})],1),_c('el-form-item',{attrs:{"label":"科目分类","rules":[
          { required: true, message: '请选择科目分类', trigger: 'blur' },
        ],"prop":"courseType"}},[_c('v-select',{attrs:{"disabled":"","options":_vm.classes},model:{value:(_vm.form.courseType),callback:function ($$v) {_vm.$set(_vm.form, "courseType", $$v)},expression:"form.courseType"}})],1),_c('el-form-item',{attrs:{"label":"章节名称","rules":[
          { required: true, message: '请输入章节名称', trigger: 'blur' },
        ],"prop":"chapterId"}},[_c('v-select',{attrs:{"disabled":"","filterable":"","options":_vm.chapterList},model:{value:(_vm.form.chapterId),callback:function ($$v) {_vm.$set(_vm.form, "chapterId", $$v)},expression:"form.chapterId"}})],1),_c('el-form-item',{attrs:{"label":"图片","rules":[
          {
            required: true,
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ],"prop":"imageUrl"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.imageUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "imageUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "imageUrl", $event)}}})],1),_c('el-form-item',{attrs:{"label":"排序","rules":[{ required: true, message: '请输入排序', trigger: 'blur' }],"prop":"sortIndex"}},[_c('v-input',{attrs:{"placeholder":"请输入排序","width":250,"type":"number"},model:{value:(_vm.form.sortIndex),callback:function ($$v) {_vm.$set(_vm.form, "sortIndex", $$v)},expression:"form.sortIndex"}})],1),_c('el-form-item',{attrs:{"label":"课程名称","rules":[
          { required: true, message: '请输入课程名称', trigger: 'blur' },
        ],"prop":"courseTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入课程名称","width":250},model:{value:(_vm.form.courseTitle),callback:function ($$v) {_vm.$set(_vm.form, "courseTitle", $$v)},expression:"form.courseTitle"}})],1),_c('el-form-item',{attrs:{"label":"老师","rules":[{ required: true, message: '请输入老师', trigger: 'blur' }],"prop":"teacher"}},[_c('v-input',{attrs:{"placeholder":"请输入老师","width":250},model:{value:(_vm.form.teacher),callback:function ($$v) {_vm.$set(_vm.form, "teacher", $$v)},expression:"form.teacher"}})],1),_c('el-form-item',{attrs:{"label":"学校","rules":[{ required: true, message: '请输入学校', trigger: 'blur' }],"prop":"school"}},[_c('v-input',{attrs:{"placeholder":"请输入学校","width":250},model:{value:(_vm.form.school),callback:function ($$v) {_vm.$set(_vm.form, "school", $$v)},expression:"form.school"}})],1),_c('el-form-item',{attrs:{"label":"课程简介","rules":[
          { required: true, message: '请输入课程简介', trigger: 'blur' },
        ],"prop":"courseDesc"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"maxLength":20000},model:{value:(_vm.form.courseDesc),callback:function ($$v) {_vm.$set(_vm.form, "courseDesc", $$v)},expression:"form.courseDesc"}})],1)]),_c('el-form-item',{attrs:{"label":"教学目标","rules":[
          { required: true, message: '请输入教学目标', trigger: 'blur' },
        ],"prop":"courseObjective"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"maxLength":20000},model:{value:(_vm.form.courseObjective),callback:function ($$v) {_vm.$set(_vm.form, "courseObjective", $$v)},expression:"form.courseObjective"}})],1)]),_c('el-form-item',{attrs:{"label":"上传视频","rules":[
          { required: false, message: '请选择上传方式', trigger: 'blur' },
        ],"prop":"uploadWay"}},[_c('v-radio',{attrs:{"radioObj":_vm.radioObj},on:{"change":_vm.change},model:{value:(_vm.form.uploadWay),callback:function ($$v) {_vm.$set(_vm.form, "uploadWay", $$v)},expression:"form.uploadWay"}})],1),(_vm.form.uploadWay == 1)?_c('el-form-item',{attrs:{"label":"视频链接","rules":[
          { required: false, message: '请选择视频链接', trigger: 'blur' },
        ],"prop":"videoUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入视频链接","width":450},model:{value:(_vm.form.videoUrl),callback:function ($$v) {_vm.$set(_vm.form, "videoUrl", $$v)},expression:"form.videoUrl"}})],1):_vm._e(),(_vm.form.uploadWay == 2)?_c('el-form-item',{attrs:{"label":"直接上传","rules":[{ required: false, message: '请上传视频', trigger: 'blur' }],"prop":"videoUrl"}},[_c('v-videoUpload',{attrs:{"limit":1,"videoUrls":_vm.form.videoUrl},on:{"update:videoUrls":function($event){return _vm.$set(_vm.form, "videoUrl", $event)},"update:video-urls":function($event){return _vm.$set(_vm.form, "videoUrl", $event)}}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"上传课件","rules":[
          { required: false, message: '请选择上传方式', trigger: 'blur' },
        ],"prop":"uploadClassWay"}},[_c('v-radio',{attrs:{"radioObj":_vm.radioObj},on:{"change":_vm.change},model:{value:(_vm.form.uploadClassWay),callback:function ($$v) {_vm.$set(_vm.form, "uploadClassWay", $$v)},expression:"form.uploadClassWay"}})],1),(_vm.form.uploadClassWay == 1)?_c('el-form-item',{attrs:{"label":"课件链接","rules":[
          { required: false, message: '请选择课件链接', trigger: 'blur' },
        ],"prop":"attachmentUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入课件链接","width":450},model:{value:(_vm.form.attachmentUrl),callback:function ($$v) {_vm.$set(_vm.form, "attachmentUrl", $$v)},expression:"form.attachmentUrl"}})],1):_vm._e(),(_vm.form.uploadClassWay == 2)?_c('el-form-item',{attrs:{"label":"直接上传","rules":[{ required: false, message: '请上传课件', trigger: 'blur' }],"prop":"attachmentUrl"}},[_c('v-fileUpload',{attrs:{"limit":1,"imgUrls":_vm.form.attachmentUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "attachmentUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "attachmentUrl", $event)}}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }